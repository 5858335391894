// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-service-detailpage-js": () => import("./../../../src/components/templates/serviceDetailpage.js" /* webpackChunkName: "component---src-components-templates-service-detailpage-js" */),
  "component---src-components-templates-service-singlepage-js": () => import("./../../../src/components/templates/serviceSinglepage.js" /* webpackChunkName: "component---src-components-templates-service-singlepage-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exclusive-sanity-exclusive-slug-current-js": () => import("./../../../src/pages/exclusive/{SanityExclusive.slug__current}.js" /* webpackChunkName: "component---src-pages-exclusive-sanity-exclusive-slug-current-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insight-sanity-insight-slug-current-js": () => import("./../../../src/pages/insight/{SanityInsight.slug__current}.js" /* webpackChunkName: "component---src-pages-insight-sanity-insight-slug-current-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-project-sanity-case-slug-current-js": () => import("./../../../src/pages/project/{SanityCase.slug__current}.js" /* webpackChunkName: "component---src-pages-project-sanity-case-slug-current-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

