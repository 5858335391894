import "./src/styles/swiperjs.css";
import "./src/styles/global.css";
import "./src/styles/bodyBlockContent.css";
import "./src/styles/buttons.css";
import "./src/styles/teaserMedia.css";
import "./src/styles/slider.css";

export const onClientEntry = () => {
  setTimeout(() => {
    document.querySelector('body').classList.remove("loading");
    document.querySelector('body').classList.add("loaded");
  }, 250);

  window.onload = () => {
    // scroll to element if we have a hash
    if (document.location.hash) {
      const id = document.location.hash.substr(1);
      const elem = document.getElementById(id);
      if (elem) {
        document.querySelector(".tro-page-content")?.scrollTo({
          top:
            elem.offsetTop +
            document.querySelector("header").getBoundingClientRect()
              .height - 100,
          behavior: "smooth",
        });
      }
    }
  }
}
